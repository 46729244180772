import React from 'react';
import PropTypes from 'prop-types';
import css from './SectionCustomColumn.module.css';

const SectionCustomColumn = ({ sectionId, title, text, blocks }) => {
    const button1 = blocks[0].callToAction;
    const button2 = blocks[1].callToAction;

    return (
        <div className={css['section-custom-column-container']}>
            <div className={css['section-custom-column']}>
                <h1>{title}</h1>
                <p>{text}</p>
                <div className={css['section-custom-column-buttons']}>
                    <a href={button1.href} target={button1.fieldType === 'externalButtonLink' ? "_self" : "_self"}>
                        <button className={css['section-custom-column-button']}>{button1.content}</button>
                    </a>
                    <a href={button2.href} target={button2.fieldType === 'externalButtonLink' ? "_self" : "_self"}>
                        <button className={css['section-custom-column-button']}>{button2.content}</button>
                    </a>
                </div>
            </div>
        </div>
    );
};

SectionCustomColumn.propTypes = {
    sectionId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    blocks: PropTypes.arrayOf(
        PropTypes.shape({
            blockType: PropTypes.string.isRequired,
            blockId: PropTypes.string.isRequired,
            callToAction: PropTypes.shape({
                fieldType: PropTypes.string.isRequired,
                href: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
            }).isRequired,
        })
    ).isRequired,
};

export default SectionCustomColumn;