import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';
import { useEffect, useRef } from 'react';



const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);

  const videoRef = useRef(null);


  useEffect(() => {
    const mobileBreakpoint = 768; 
    const isMobile = window.innerWidth <= mobileBreakpoint;

    if (!isMobile && videoRef.current && media.fieldType === 'video') {
      if (videoRef.current.paused) {
        videoRef.current.play();
      }
    } else if (isMobile && videoRef.current && media.fieldType === 'video') {
      videoRef.current.pause();
      videoRef.current.currentTime = 2;
    }
  }, [media]);

  return hasMediaField ? (
    <div className={classNames(className, css.media)} style={{ maxWidth: '500px' }}>
      {media.fieldType === 'video' ? (
        <div>
          <video ref={videoRef} src={media.content} loop muted playsinline style={{ width: '100%' }} />
        </div>
      ) : (
        <Field data={media} sizes={sizes} options={options} />
      )}
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} />
          <Field data={text} options={options} />
          <div>
            <Field data={callToAction} className={ctaButtonClass} options={options} />
          </div>
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
