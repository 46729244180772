import React, { useEffect } from 'react';
import css from './TypeformEmbed.module.css';

export const TypeformEmbed = ({ isVisible, typeformId }) => {
    useEffect(() => {
        if (isVisible) {
            const script = document.createElement('script');
            script.src = "//embed.typeform.com/next/embed.js";
            script.async = true;
            document.body.appendChild(script);

            return () => {
                // Cleanup the script when the component unmounts
                document.body.removeChild(script);
            };
        }
    }, [isVisible]);

    if (!isVisible) return null;

    return (
        <div className={css.feedback}>
            <div data-tf-live={typeformId}></div>
        </div>
    );
};